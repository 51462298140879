@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,700&display=swap');

#root {
    height: 100vh;
}
html {
    scroll-behavior: smooth;
    /*
    -webkit-transform: translate3d(0, 0, 0);
     */
    -webkit-overflow-scrolling: touch;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #cfcfcf;
}

a, p {
    font-family: 'Roboto', sans-serif;
}
.landing-image-container {
    height: fit-content;
    transition: transform 1s cubic-bezier(0.81, -0.01, 0.23, 0.99);
    position: fixed;
    width: 100%;
}
.landing-image-container:first-child {
    top: 0;
}
.landing-image-container {
    bottom: -5px;
}

.landing-image-container.barcelo img {
    margin-top: -35pt;
}
.landing-image-container.maraska img {
    margin-bottom: -1pt;
}

@media only screen
and (min-width: 375px)
and (min-height: 800px)
and (orientation: portrait) {

    .landing-image-container img {
        width: 125vw !important;
        max-width: 130vw !important;
        max-height: 50vh !important;
        object-fit: fill !important;
    }

    .landing-image-container.barcelo img {
        margin-top: 0;
    }

    .landing-image-container.maraska img {
        margin-bottom: 0;
    }
}

.landing-image-container img {
    width: 115vw;
    max-width: 120vw;
    max-height: 50vh;
    object-fit: fill;
}

body {

}
#root {
    /*max-height: 100%;*/
    /*overflow: hidden;

     */
}
.main {
    /*
    max-height: 100%;
    
     */
    /*overflow: hidden;

     */
}
.landing-page {
    max-height: 100vh;
    overflow: hidden;
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.landing-page-mid {
    /*background: transparent;*/
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999999;
    max-height: fit-content;
    margin: auto;
    width: 100%;
}

img.logo {
    max-height: 100pt;
    object-fit: contain;
}
.landing-page-container.top {
    background-color: #fff;
    height: 33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 8%;
}
.landing-page-container.mid {
    height: 100%;
    background-color: #0090CD;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 8%;
}
.mid-container {
    z-index: 9999;
    position: relative;
    text-align: center;
    margin: 3em 0;
}
.animated-logo {
    animation: flip-logo 2s forwards;
    animation-delay: 1.5s;
    transform-style: preserve-3d;
}

@keyframes flip-logo {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.landing-page-text {
    font-size: 19pt;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    text-align: center;
    margin: 0;
    transition: opacity 1s ease-in-out;
}
.landing-page-text.title {
    margin: 0.15em;
}
.landing-page-text.subtitle {
    font-size: 13pt;
    margin: 1em;
}
.small-text {
    font-size: 12pt;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    text-align: center;
    margin: 0.5em 0;
    transition: opacity 1s ease-in-out;
}
.button.alt:hover, .button {
    display: flex;
    text-align: center;
    width: fit-content;
    background-color: white;
    color: #0090CD;
    border: 2px solid #0090CD;
    padding: 10pt;
    border-radius: 4pt;
    font-weight: bold;
    font-size: 14pt;
    cursor: pointer;
}
.button.alt, .button:hover {
    background-color: #0090CD;
    color: #ffffff;
    border: 2px solid #0090CD;
}
.button.alt {
    min-width: 40pt;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.button.default:hover {
    display: flex;
    text-align: center;
    background-color: white;
    color: #0090CD;
    border: 2px solid #0090CD;
    padding: 10pt;
    border-radius: 4pt;
    font-weight: bold;
    font-size: 14pt;
}
.button.default {
    background-color: #0090CD;
    color: #ffffff;
    border: 2px solid #0090CD;
    width: inherit;
    margin: 0 8%;
}
.button.default {
    min-width: 40pt;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.button.join-now {
    margin-top: 48px;
    margin-bottom: 30px;
}

.footer {
    position: fixed;
    bottom: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    bottom: 7vh;
    position: relative;
    max-width: 450px;
}
.product-img {

}


.arrows-container {
}
.arrow {
    position: absolute;
    z-index: 1000;
    width: 100%;
    zoom: 0.50;
    display: flex;
    flex-direction: row;
}
.arrow.up {
    justify-content: flex-start;
}
.arrow.down {
    transform: rotateZ(180deg);
}
.arrow.up img {
    top: -110pt;
}
.arrow.down img {
    bottom: 110pt;
}

.img-arrow {
    position: absolute;
    z-index: 1000;
}

.logo, .logo-makarska, .prop {
    position: absolute;
}
.logo > img {
    width: 50pt;
}
.logo-makarska > img {
    width: 75pt;
}
.logo {
    top: 20px;
}
.logo-makarska {
    bottom: 20px;
}
.prop {
    transition: transform 1s cubic-bezier(0.81, -0.01, 0.23, 0.99);
}
.prop.icon-sun {
    left: 13%;
    top: 2%
}
.prop.icon-sun img {
    width: 36pt;
}
.prop.icon-umbrella {
    right: 2%;
    top: 12%
}
.prop.icon-umbrella img {
    width: 105pt;
}
.prop.icon-drink-tropic {
    right: 18%;
    top: 27%;
}
.prop.icon-drink-tropic img {
    width: 35pt;
}
.prop.icon-drink {
    left: 15%;
    bottom: 10%;
}
.prop.icon-drink img {
    width: 38pt;
}
.prop.icon-sail {
    right: 18%;
    bottom: 19%;
}
.prop.icon-sail img {
    width: 48pt;
}
.prop.icon-shell {
    left: 3%;
    bottom: 6%;
}
.prop.icon-shell img {
    width: 38pt;
}

.transition-container {
    position: absolute;
    z-index: 999;
    width: 100%;
    left: 0;
    right: 0;
}
.landing-page-mid .transition-line {
    width: 100%;
    height: auto;
    top: -32px;
    position: relative;
}
.landing-page-mid .transition-line.bot {
    overflow: visible;
    position: initial;
    transform: rotateX(180deg) translate(-110pt, 0);
    margin-top: -2px;
}

/*  PRODUCT LISTINGS */
.listings-page {
    overflow-x: hidden;
    /*
    -webkit-transform: translate3d(0, 0, 0);
     */
    -webkit-overflow-scrolling: touch;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.header {
    position: fixed;
    z-index: 999;
    top: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 25vh;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}
.header-logo.small {
    height: 54pt;
    object-fit: contain;
    margin: 8pt 0;
}
.header-bcg-image {
    width: 100vw;
    max-height: 100%;
    position: absolute;
    z-index: -1;
    object-fit: cover;
    opacity: 0.32;
}
.header-transition-img {
    position: absolute;
    width: 102%;
    max-width: 102%;
    bottom: -15px;
}
.footer-bcg-image {
    width: 100%;
    max-height: 100%;
    position: absolute;
    z-index: -1;
    object-fit: cover;
    opacity: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.footer:hover .footer-bcg-image {
    opacity: 0.32;
}

.header-container-padding {
    padding-top: 20px;
}
.header-text {
    margin: 0;
    color: white;
    font-size: 17pt;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-align: center;
    transition: opacity 1s ease-in-out;
}

.footer-text {
    color: white;
    font-size: 10pt;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-align: center;
}
ul.products.list {
    -webkit-overflow-scrolling: touch;
    position: relative;
    outline: none;
    list-style: none;
    /*
    overflow-y: scroll;
    overflow-x: hidden;
     */
    padding: 0;
    padding-bottom: 30pt;
    width: 85% !important;
    margin-top: 24vh;
}
ul.products.list li {
    position: relative;
    margin-top: 70pt;
    padding: 0;
    outline: none;
    list-style: none;

}
.row {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40pt 0;
}
.question {
    font-weight: normal;
    font-size: 16pt;
    color: black;
    margin-bottom: 12pt;
    text-align: center;
}
.question.description {
    font-weight: lighter;
    font-size: 13pt;
    color: #3B3B3B;
    text-align: left;
    padding: 0 8%;
}


.body.results {
    height: 100%;
    min-height: 100vh;
    position: fixed;
    width: 100%;
    padding-bottom: 25vh;
    margin-top: 25vh;
}
.results-side-legend {
    position: absolute;
    height: 95vw;
    top: 14vw;
    left: -2vh;
}

.chart-container {
    margin-top: 20px;
    /* max-height: 400px; */
    left: 16px;
    position: relative;
  /*  margin-bottom: 80px; */
}
.VictoryContainer {
    height: auto !important;
    margin-left: 21px;
}
.VictoryContainer text tspan {
    font-size: 11px !important;
    margin-bottom: 12px;
}

.VictoryContainer svg {
    pointer-events: none !important;
}

.results-container {
    padding: 0 7%;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.results-container .results-text {
    text-align: center;
    font-size: 1.10em;
    text-align: left;
}

.results-container-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.product-img {
    max-height: 350px;
}
.row.product {
    width: inherit;
}
.p-title, .p-stats-header {
    padding: 0px 15%;
    font-size: 1.5em;
    color: #000E52;
    margin-bottom: 0px;
    margin-top: 0;
}
.p-title.extra-title {
    font-size: 1.45em !important;
    line-height: 2.95em !important;
    font-weight: 300 !important;
}
.p-description, .p-store {
    padding: 0px 7%;
}
.p-store {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.p-store svg path, .p-store svg circle {
    fill: #000E52;
}
.p-store .p-url {
    padding-left: 5px;
}
.p-url {
    font-size: 1em;
    color: #000E52;
    font-weight: bolder;
}
.p-bold {
    color: #0090cd !important;
    font-weight: bolder;
}
.p-small {
    margin: 8% 10%;
    font-size: 0.85em;
    font-weight: normal;
    color: #000E52;
    text-decoration: underline;
}
.p-text {
    font-size: 0.95em;
    font-weight: normal;
}
.p-cancel {
    font-size: 0.80em;
    font-weight: normal;
    text-decoration: underline;
    margin: 0;
    padding: 0;
}
.p-subscription-info, .p-subscription-disclaimer {
    font-size: 0.80em;
    font-weight: normal;
    text-decoration: none;
    margin-top: 0px;
}
.p-subscription-disclaimer {
    font-size: 0.78em;
    padding-top: 24px;
    padding-bottom: 80px;
}
.p-subscription {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
}



.p-subscription span.price-tag{
    padding-top: 4px;
}
.p-subscription span.price{
    font-weight: 400;
    padding-bottom: 0;
    font-size: 0.95em;
    display: flex;
    flex-direction: column;
}
.p-subscription span.tax{
    margin-top: 2px;
    border-top: 1px solid #000E52;
    font-weight: 400;
    padding-bottom: 0;
    font-size: 0.68em;
    align-self: flex-end;
}
.icon-cancel path {
    fill: gray;
}

ul {
    outline: none;
    width: auto;
    margin: 0;
    padding: 0;
    /*padding-bottom: 25vh;*/
}
.product-icon {
    width: 10px;
    height: 10px;
}

.product.right {
    width: 100%;
}


.row-box {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #F3F3F3;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 14pt;
    border-radius: 12px;
}
.row-box.recipe {
    margin-top: 1pt;
    padding-top: 10pt;
    padding-top: 10pt;
}
.prod-img {
    width: 34pt;
    padding: 0 20px 0 25px;
}
.prod-col {

}
.prod-recipe {
    list-style: circle !important;
}
.prod-recipe li {
    color: #3B3B3B;
    font-family: 'Roboto', sans-serif;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 10pt;
    list-style: circle !important;
}
.prod-recipe-name {
    font-family: 'Roboto', sans-serif;
    padding-left: 2pt;
    font-weight: 500;
    font-size: 11pt;
    width: 56pt;
    text-align: center;
    margin: 0.05em 0;
}
.prod-name {
    font-size: 13pt;
    font-weight: bold;
}
.prod-description {
    font-size: 10pt;
    padding-right: 15pt;
    color: #3B3B3B;
    margin-top: 24pt;
}
.prod-name, .prod-img {
    margin-top: -24pt;
}
.icon-bcg-container {
    position: absolute;
    right: 4pt;
    top: -11pt;
}
.icon-bcg {
    width: 25pt;
}
.icon-drink-type {
    width: 10pt;
    object-fit: contain;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 2pt;
    margin: auto;
}


.login-container {
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 0 120px;
    background: #fff;
    border-radius: 25px;
}
.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.login-container .message {
    margin-bottom: -20px;
}
.checkbox-container {
    margin-top: 22px;
}
.checkbox-container input {
    margin: 0 12px;
    height: 50px;
    width: 50px;
    max-width: 50px;
    max-height: 50px;
    margin-right: 20px;
    background-color: #0090cd;
}
.checkbox-container input:checked {
    background: #D30263;
}
.checkbox-container label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
}
.container-results-conditions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.results-conditions {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 600px;
    overflow: hidden;
    padding: 0 8%;
    padding-bottom: 120px;
}
input {
    width: auto;
}


/* REGISTER */
.flex-page-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 25vh;
    padding: 0 8%;
    padding-top: 10%;
    max-width: 1600px;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 8%;
}
.form-container form {
    width: inherit;
}
.login-container .message {
    margin-bottom: -20px;
}
.checkbox-container {
    margin-top: 22px;
}
.checkbox-container input {
    margin: 0 14px;
    height: 30px;
    width: 30px;
    min-height: 15px;
    min-width: 15px;
}
.checkbox-container.remember input {
    margin: 0 12px;
    height: 15px;
    width: 15px;
}

.checkbox-container input:checked {
    background: #008941;
}
.checkbox-container label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
}
.p-title.fixed {
    width: 100%;
    background: white;
    z-index: 99;
    position: fixed;
    top: 25vh;
    padding-top: 4%;
    font-weight: normal;
    align-self: flex-start;
    padding-left: 8%;
    left: 0;
    padding-bottom: 4%;
}
.p-title.fixed.transparent {
    /*background: transparent;*/
    background: white;
}
.p-alert {
    font-size: 0.88em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
    color: #FFFFFF;
    background-color: #0090CD;
    width: 100%;
    height: 28px;
    border-radius: 5px;
    padding: 0 10px;
    padding-left: 260px;
}

.p-red {
    color: darkred;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.88em;
}

/* COLLAPSIBLE*/

.Collapsible {
    width: 100% !important;
    margin: 2px 0;
    position: fixed;
    top: 26vh;
    right: -20px;
    z-index: 9999;
    max-width: 140px;
}
.Collapsible__contentOuter {
    margin-top: -90px;
    width: max-content;
    margin-left: -167px;
}
.collapsible-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid rgba(255, 255, 255, 0.42);
}
.collapsible-header.l10n-options {
    justify-content: flex-end;
    border: none;
}
.collapsible-header.l10n-options > div {
    padding: 0 8px;
}
.collapsible-header.l10n-options svg {
    color: rgba(255, 255, 255, 0.45) !important;
}
.l10n-options-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 28px;
}
.l10n-options-content .country-label-container {
    padding: 0 5px;
    padding-left: 10px;
    z-index: 9999;
}

.stats-container .stats-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.stats-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    padding-bottom: 90px;
}
.stats-col {
    max-width: 25%;
    width: 25%;
    text-align: center;
}
.stats-row.legend .p-stats {
    overflow-wrap: break-word;
}
.stats-row.legend .p-stats {
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 0.75em;
}
.stats-row .p-stats {
    margin-bottom: 9px;
    font-size: 1em;
}
.stats-row.legend {
    align-items: flex-start;
    padding-bottom: 8px;
}
.p-stats-header {
    align-self: flex-start;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-left: 0;
}
.support-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.support-animated {
    background-color: #0090CD;
    height: 22px;
    width: 150px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 0;
    margin-left: -10px;
    margin-top: 4px;
    box-shadow: 1px 2px 2px rgba(87, 87, 87, 0.20);
}

.home-subscription-inactive p {
    width: 100%;
}
.home-subscription-inactive {
    height: 100%;
    justify-content: space-between;
}


/* DESKTOP */

@media only screen
and (min-width: 900px) {
    .body.results {
        max-width: 600px;
    }

    .chart-container {
        /*  margin-bottom: 270px; */
    }

    .results-side-legend {
        position: absolute;
        height: 64vh;
        top: 8.8vh;
        left: -3.9vh;
    }
    .chart-container {
        left: 28px;
    }
}

@media only screen
and (max-width: 900px) {
    .VictoryContainer {
        left: 6%;
    }
}


@media only screen
and (max-width: 800px) {
    .VictoryContainer {
        left: 6%;
    }
}

@media only screen
and (max-width: 700px) {
    .VictoryContainer {
        left: 4%;
    }
}

@media only screen
and (max-width: 600px) {
    .VictoryContainer {
        left: 3%;
    }
}

@media only screen
and (max-width: 500px) {
    .VictoryContainer {
        left: 2%;
    }
}

@media only screen
and (max-width: 400px) {
    .VictoryContainer {
        left: 0%;
    }
}

/* SMALLER SCREENS */

@media only screen
and (max-width: 700px) {
    .listings-page .flex-page-container .form-container {
        padding-top: 24px;
    }
}

/* BIGGER SCREENS */

@media only screen
and (min-width: 1400px) {
    .flex-page-container {
        padding-top: 5%;
    }
    .p-title.fixed {
        padding-bottom: 2%;
    }
    .listings-page .flex-page-container .form-container {
        padding-top: 48px;
    }

}